/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useFoodVendorStore = defineStore('food_vendor', () => {
  const foodVendors = shallowRef<FoodVendors>({});
  const contactPersons = shallowRef<ContactPersons>({});
  const myFoodVendors = shallowRef<MyFoodVendors>({});

  const getFoodVendorById = generateGetterById<FoodVendor>(foodVendors);
  const getContactPersonById = generateGetterById<ContactPerson>(contactPersons);
  const getMyFoodVendorById = generateGetterById<MyFoodVendor>(myFoodVendors);

  const fetchFoodVendor = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-food-vendor', foodVendors, filter);
  };

  const fetchFoodVendors = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-food-vendors', foodVendors, filter);
  };

  const fetchActiveFoodVendors = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-active-food-vendors', foodVendors, filter);
  };

  const fetchContactPerson = async (
    filter: {food_vendor_id: string; contact_person_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-contact-person', contactPersons, filter);
  };

  const fetchContactPersons = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-contact-persons', contactPersons, filter);
  };

  const fetchMyFoodVendor = async (filter: {food_vendor_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('food-vendor.find-my-food-vendor', myFoodVendors, filter);
  };

  const submitApplication = async (data: {
    food_vendor_application_id?: string;
    company_name: any;
    company_owner: object;
    company_address: object;
    company_contact: object;
    vendor_category: string;
  }): Promise<string> => {
    data['food_vendor_application_id'] ??= generateAggregateId();
    await command('food-vendor.submit-application', data);
    return data['food_vendor_application_id'];
  };

  const setVendorCategories = async (data: {food_vendor_id?: string; vendor_categories: object}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-vendor-categories', data);
    return data['food_vendor_id'];
  };

  const addContactPerson = async (data: {
    contact_person_id?: string;
    food_vendor_id: string;
    contact_person_name: any;
    contact_person_function: string;
    email_address: any;
    mobile_number: any;
  }): Promise<string> => {
    data['contact_person_id'] ??= generateAggregateId();
    await command('food-vendor.add-contact-person', data);
    return data['contact_person_id'];
  };

  const setCompanyName = async (data: {food_vendor_id?: string; company_name: any}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-company-name', data);
    return data['food_vendor_id'];
  };

  const setCompanyAddress = async (data: {food_vendor_id?: string; company_address: object}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-company-address', data);
    return data['food_vendor_id'];
  };

  const setFoodVendorTags = async (data: {food_vendor_id?: string; food_vendor_tags: any}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-food-vendor-tags', data);
    return data['food_vendor_id'];
  };

  const setFoodVendorNotes = async (data: {food_vendor_id?: string; food_vendor_notes: string}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-food-vendor-notes', data);
    return data['food_vendor_id'];
  };

  const setFoodVendorCustomerNumber = async (data: {
    food_vendor_id?: string;
    customer_number: string;
  }): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-food-vendor-customer-number', data);
    return data['food_vendor_id'];
  };

  const setFoodVendorBankAccount = async (data: {food_vendor_id?: string; bank_account: object}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-food-vendor-bank-account', data);
    return data['food_vendor_id'];
  };

  const setFoodVendorVatId = async (data: {food_vendor_id?: string; vat_id: string}): Promise<string> => {
    data['food_vendor_id'] ??= generateAggregateId();
    await command('food-vendor.set-food-vendor-vat-id', data);
    return data['food_vendor_id'];
  };

  return {
    foodVendors,
    contactPersons,
    myFoodVendors,
    getFoodVendorById,
    getContactPersonById,
    getMyFoodVendorById,
    fetchFoodVendor,
    fetchFoodVendors,
    fetchActiveFoodVendors,
    fetchContactPerson,
    fetchContactPersons,
    fetchMyFoodVendor,
    submitApplication,
    setVendorCategories,
    addContactPerson,
    setCompanyName,
    setCompanyAddress,
    setFoodVendorTags,
    setFoodVendorNotes,
    setFoodVendorCustomerNumber,
    setFoodVendorBankAccount,
    setFoodVendorVatId,
  };
});

export interface FoodVendor {
  food_vendor_id: string;
  company_name: string;
  properties: {
    contact?: {email_address: string; phone_number: string; website: string};
    address?: {
      town: string;
      address_addition: string;
      postal_routing_number: string;
      street_and_house_number: string;
      country: string;
    };
    owner?: {first_name: string; last_name: string};
    bank_account?: {iban: string; holder: string};
    vat_id?: string;
  };
  tags: string[];
  notes: string;
  customer_number: string | null;
  vendor_categories: string[];
  active: boolean;
}

export interface ContactPerson {
  contact_person_id: string;
  food_vendor_id: string;
  full_name: string;
  function: string;
  mobile_number: string;
  email_address: string;
}

export interface MyFoodVendor {
  food_vendor_id: string;
  company_name: string;
  properties: {
    contact?: {email_address: string; phone_number: string; website: string};
    address?: {
      town: string;
      address_addition: string;
      postal_routing_number: string;
      street_and_house_number: string;
      country: string;
    };
    owner?: {first_name: string; last_name: string};
    bank_account?: {iban: string; holder: string};
    vat_id?: string;
  };
}

export interface FoodVendors {
  [key: string]: FoodVendor;
}

export interface ContactPersons {
  [key: string]: ContactPerson;
}

export interface MyFoodVendors {
  [key: string]: MyFoodVendor;
}
